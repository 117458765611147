import React from 'react';
import { useHistory,} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Title';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}






const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function Orders({value}) {
  const classes = useStyles();

  const history = useHistory();


  function preventDefault(event) {
    event.preventDefault();
    history.push("compras")
  }


  const [rowsT, setRows] = React.useState([])
  

  React.useEffect(()=> {
   setRows(value||[])
  },[value])


  return (
    <React.Fragment>
      <Title>Viagens Recentes</Title>
      <Table size="small">
      <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Cliente</TableCell>
            <TableCell>Telefone</TableCell>
            <TableCell>Serviço</TableCell>
            <TableCell>Duração</TableCell>
            <TableCell>Método de Pagamento</TableCell>
            <TableCell>Motorista</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell align="right">Total Pago</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsT?.reverse().slice(0,5).map((row) => (
            <TableRow key={row._id}>
            <TableCell>{row?.DateTrip.slice(0,10)}</TableCell>
             <TableCell>{row?.Customer?.Profile?.fullName}</TableCell>
             <TableCell>{row?.Customer?.PhoneNumber}</TableCell>
             <TableCell>{`${row?.KindOfService=="Travel"?"Viagem":"Entrega"} - ${row?.VehicleType=='CaroPopular'?"Normal":"executive"?"Executiva":"Familhar"}`}</TableCell>
             <TableCell>{`${row?.distance}kM - ${row?.durration}min`}</TableCell>
             <TableCell>{'Mpesa'}</TableCell>
             <TableCell>{row?.Driver?.Profile?.fullName}</TableCell>
             <TableCell>{`${row?.TripCanceled?.status?"Cancelado":"Finalizada"} ${row?.TripCanceled?.status?row?.TripCanceled?.author:""}`}</TableCell>
             <TableCell align="right">{Intl.NumberFormat("pt-BR", {style:"currency", currency: "MZN"}).format(!row?.TripCanceled?.status ?row?.TripPrice:0)}</TableCell>
           </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link color="primary" href="/compras" onClick={preventDefault}>
          Ver mais
        </Link>
      </div>
    </React.Fragment>
  );
}